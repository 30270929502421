<template>
    <modal ref="modalAnadirImagen" titulo="Añadir Imagen" no-aceptar adicional="Guardar" :cargado-modal="loading" :tamano="showSection ? 'modal-xl' : 'modal-md'" @adicional="guardar">
        <ValidationObserver ref="form" class="w-100">
            <div class="row">
                <div class="col">
                    <div class="row mx-0 justify-center">
                        <div class="col-10">
                            <p class="text-general f-13 pl-3">Nombre de la imagen</p>
                            <ValidationProvider v-slot="{ errors }" rules="required|max:80" class="w-100" name="nombre">
                                <el-input v-model="nombre_publicidad" size="small" class="w-100" />
                                <p class="f-11 text-danger">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 mt-4">
                            <p class="text-general f-13 pl-3">Seleccionar carpeta a guardar</p>
                            <ValidationProvider v-slot="{ errors }" rules="required" class="w-100" name="carpeta">
                                <el-select v-model="id_carpeta" :disabled="disabled" size="small" placeholder="Seleccionar carpeta" class="w-100">
                                    <el-option
                                    v-for="item in carpetas"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <p class="f-11 text-danger">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto mt-4">
                            <slim-cropper
                            ref="imgAnuncio"
                            :options="slimOptions"
                            class="border cr-pointer publicidad-picture"
                            />
                            <p class="text-general mt-2 text-right f-14">
                                Aspecto 2:3
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="showSection" class="col">
                    <div class="row mx-0 justify-center">
                        <div class="col-10">
                            <p class="text-general f-13 pl-3">Vigencia</p>
                            <el-date-picker
                            v-model="fechas"
                            class="w-100"
                            size="small"
                            type="datetimerange"
                            range-separator="-"
                            start-placeholder="Inicio"
                            end-placeholder="Fin"
                            value-format="yyyy-MM-dd HH:mm"
                            format="dd-MMM-yyyy hh:mm a"
                            />
                        </div>
                        <div class="col-10 py-3" />
                        <div class="col-10 mt-4">
                            <p class="text-general f-13">Redirigir a:</p>
                            <div class="row mx-0 mt-3">
                                <el-radio-group v-model="redireccion" class="options-pop">
                                    <el-radio class="radio-dark" :label="0">
                                        Sin redirección
                                    </el-radio>
                                    <el-radio class="radio-dark" :label="1">
                                        Categoría
                                    </el-radio>
                                    <el-radio class="radio-dark" :label="2">
                                        Promoción
                                    </el-radio>
                                    <el-radio class="radio-dark" :label="3">
                                        Producto
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div v-if="redireccion != 0" class="col-10 mt-4">
                            <p class="text-general f-13 pl-3">Seleccionar redirección</p>
                            <ValidationProvider v-slot="{ errors }" :rules="{required: redireccion != 0}" class="w-100" name="redirección">
                                <el-select v-model="idRedireccion" v-loading="loadingSelect" size="small" placeholder="Seleccionar" class="w-100" filterable>
                                    <el-option
                                    v-for="item in recordsRedireccion"
                                    :key="item.id"
                                    
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <p class="f-11 text-danger">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Publicidad from "~/services/publicidad/publicidad_admin";
export default {
    data(){
        return {
            nombre_publicidad: "",
            id_carpeta: null,
            carpetas: [],
            fechas: null,
            slimOptions: {
                ratio: '2:3',
                label: "Agregar Imagen",
                buttonCancelLabel: "Cancelar",
                buttonConfirmLabel: "ok",
            },
            loading:false,
            loadingSelect:false,
            disabled:false,
            tipo: 1, // 1 Cliente 2 Leechero
            id_cedis: null,
            redireccion: 0,
            idRedireccion: null,
            recordsRedireccion: [],
            showSection: true
        };
    },
    watch: {
        redireccion(){
            this.idRedireccion = null
            this.recordsRedireccion = []

            this.buscarRedireccion()
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAnadirImagen.toggle();
        },
        async listarCarpetas(){
            try {
                const { data } = await Publicidad.getCarpetas({tipo: this.tipo});
                this.carpetas = data.carpetas;
            } catch (e){
                this.error_catch(e);
            }
        },
        addImgCliente({ id_cedis, tipo, id_carpeta = null, showSection = true }){
            this.limpiar()
            this.tipo = tipo;
            this.id_cedis = id_cedis;
            this.id_carpeta = id_carpeta;
            this.disabled = id_carpeta != null
            this.showSection = showSection
            this.listarCarpetas();

            this.$refs.modalAnadirImagen.toggle();
        },
        async guardar(){
            try {
                const valid = await this.$refs.form.validate()
                if(!valid) return
                this.loading = true
                let model = {}
                const archivo = this.$refs.imgAnuncio.instanciaCrop.dataBase64.output.image
                if(!archivo){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                model.imagen = archivo
                model.titulo = this.nombre_publicidad
                model.id_carpeta = this.id_carpeta
                model.tipo = this.tipo
                model.id_cedis = this.id_cedis
                model.carpeta = this.disabled
                model.fecha_inicio = this.fechas && this.fechas[0]
                model.fecha_fin = this.fechas && this.fechas[1]
                model.redireccion = this.redireccion
                model.id_redireccion = this.idRedireccion

                if (this.showSection){
                    model.guardar_cedi = true
                }

                const {data} = await Publicidad.guardarPublicidad(model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$emit('update', data.model)
                this.$refs.modalAnadirImagen.toggle();

            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
        async buscarRedireccion(){
            try {
                this.loadingSelect = true
                let params = {
                    redireccion: this.redireccion,
                    cedis: this.id_cedis
                }
                const { data } = await Publicidad.getRedireccion(params)
                this.recordsRedireccion = data.records

            } catch (error){
                this.error_catch(error)
            } finally {
                this.loadingSelect = false
            }
        },
        limpiar(){
            this.nombre_publicidad = ""
            this.id_carpeta = null,
            this.id_cedis = null,
            this.tipo = 1
            this.redireccion = 0
            this.idRedireccion = null
            this.fechas = null

            this.recordsRedireccion = []
            this.$refs.form.reset()
            this.$refs.imgAnuncio.instanciaCrop.remove()
        }
    },
};
</script>
<style lang="scss" scoped>
.publicidad-picture {
  width: 200px;
  height: 300px;
  border-radius: 4px;
}
.options-pop{
    display: grid;
    grid-template: 25px 25px / 1fr 2fr;
}
</style>
